import React, {Component, useState, useContext, useEffect, useRef} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';
// import FileDownload from "js-file-download";

import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';


import {DataGrid, GridToolbar, GridActionsCellItem} from '@mui/x-data-grid';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import CircularProgress from "@mui/material/CircularProgress";
import Home from './Home.js';
import ApiImportFileXlsx from '../services/api/ApiImportFileXlsx';
import ApiShipmentImportFile from '../services/api/ApiShipmentImportFile';
import {AuthContext} from '../App.js';
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Swal from "sweetalert2";
import ApiSubscription from "../services/api/ApiSubscription";
import ApiLogin from "../services/api/ApiLogin";
import axios from "axios";
import Loading from "./Loading";
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import Zoom from "@mui/material/Zoom";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import {red} from "@mui/material/colors";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import {styled} from '@mui/material/styles';
import dayjs from "dayjs";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import Autocomplete from "@mui/material/Autocomplete";

const FileDownload = require('js-file-download');

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

const NoMaxWidthTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
    },
});

export default function ImportFileXlsx() {
    const trucker = [
        "Lotus - BBTDC",
        "Lotus - KKDC",
        "Lotus - LPRDC",
        "Lotus - SRRDC",
        "Lotus - WNDC",
        "Lotus - LLKDC",
        "Lotus - KKRDC"
    ]

    const {
        open,
        setOpen,
        userNPass,
        setUserNPass,
        isLoading,
        setIsLoading,
        isProfiles,
        setIsProfiles,
        isProgress,
        setIsProgress,
        profiles
    } = useContext(AuthContext);

    const truckrStatus = [
        {
            id: 1,
            name: "Tendered"
        },
        {
            id: 3,
            name: "In-Transit"
        },
        {
            id: 4,
            name: "Complete1"
        },
        {
            id: 7,
            name: "Aborted"
        }
    ]

    const [file, setFile] = useState([]);
    const [fileSize, setFileSize] = useState(0);
    const [bus, setBus] = useState(profiles.roles[0] !== 'ROLE_SUPER_ADMIN' ? profiles.bu[0] : '');
    const [makro, setMakro] = useState('');
    const [cpAllSite, setCpAllSite] = useState('');
    const [buSiteCpAllCM, setBuSiteCpAllCM] = useState([]);
    const [buSiteCpAllNR, setBuSiteCpAllNR] = useState([]);
    const [drawerOpenBtn, setDrawerOpenBtn] = useState(false);
    const [drawer, setDrawer] = useState('none');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isApi, setIsApi] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const [status, setStatus] = useState({
        debrief: false,
        truckrStatus: [],
    })

    const [rows, setRows] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const columns = [
        {field: 'no', headerName: 'ลำดับ', width: 40, editable: false, renderCell: (params) => params.no},
        {
            field: 'importedData',
            headerClassName: 'super-app-theme--header',
            headerName: 'วันที่อัพโหลด',
            minWidth: 120,
            flex: 2,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'fileName',
            headerClassName: 'super-app-theme--header',
            headerName: 'ชื่อไฟล์',
            minWidth: 350,
            flex: 5,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    params.row.fileName?.includes("API") ?
                        <div style={{ cursor: 'default' }}>{params.row.fileName}</div> :
                        <NoMaxWidthTooltip title={'ดาวน์โหลดไฟล์: ' + params.row.fileName} TransitionComponent={Zoom}
                                           placement="top" arrow>
                            <Typography
                                noWrap
                                sx={{cursor: 'pointer'}}
                                onClick={async () => {
                                    setIsLoading(true)
                                    let res = await ApiShipmentImportFile.downloadLog({
                                        fileName: params.row.generatedFileName,
                                        bu: params.row.buType
                                    });
                                    if (res.status !== 200) {
                                        setIsLoading(false)
                                        Swal.fire({
                                            title: 'ดาวน์โหลดไม่สำเร็จ',
                                            text: 'กรุณาทำรายการใหม่อีกครั้ง',
                                            icon: 'error',
                                            showConfirmButton: false,
                                        })
                                    } else {
                                        FileDownload(res.data, params.row.fileName);
                                    }

                                    setIsLoading(false)
                                }}
                            >
                                {params.row.fileName}
                            </Typography>
                        </NoMaxWidthTooltip>
                )
            }
        },
        {
            field: 'buType',
            headerClassName: 'super-app-theme--header',
            headerName: 'ลูกค้า',
            minWidth: 220,
            flex: 2,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'totalRecords',
            headerClassName: 'super-app-theme--header',
            headerName: 'รวม',
            minWidth: 60,
            flex: 2,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'result',
            headerClassName: 'super-app-theme--header',
            headerName: 'ผลลัพธ์',
            minWidth: 250,
            flex: 5,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                if (params.row.status !== 'Waiting' && params.row.status !== 'Fail') {
                    if (params.row.successRecords && params.row.failedRecords && params.row.waitingRecords) {
                        return (
                            <Grid container direction="row" justifyContent="center">
                                <Typography
                                    sx={{color: '#69C361'}}>&nbsp;สำเร็จ {' ' + params.row.successRecords}&nbsp;</Typography>/
                                <Typography
                                    sx={{color: '#ED1C24'}}>&nbsp;ไม่สำเร็จ {' ' + params.row.failedRecords}</Typography>
                            </Grid>
                        )
                    } else if (params.row.successRecords && params.row.failedRecords) {
                        return (
                            <Grid container direction="row" justifyContent="center">
                                <Typography
                                    sx={{color: '#69C361'}}>สำเร็จ {' ' + params.row.successRecords}&nbsp;</Typography>/
                                <Typography
                                    sx={{color: '#ED1C24'}}>&nbsp;ไม่สำเร็จ {' ' + params.row.failedRecords}</Typography>
                            </Grid>
                        )
                    } else if (params.row.successRecords) {
                        return (
                            <Grid container direction="row" justifyContent="center">
                                <Typography
                                    sx={{color: '#69C361'}}>สำเร็จ {' ' + params.row.successRecords}&nbsp;</Typography>
                            </Grid>
                        )
                    } else if (params.row.failedRecords) {
                        return (
                            <Grid container direction="row" justifyContent="center">
                                <Typography
                                    sx={{color: '#ED1C24'}}>ไม่สำเร็จ {' ' + params.row.failedRecords}</Typography>
                            </Grid>
                        )
                    }
                    return (
                        <Grid container direction="row" justifyContent="center">
                        </Grid>

                    )
                }
            }
        },
        {
            field: 'failBuTracking',
            headerClassName: 'super-app-theme--header',
            headerName: 'Import results',
            minWidth: 110,
            flex: 2,
            sortable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                if (params.row.status !== 'Waiting') {
                    return (
                        <>
                            <Button
                                disabled={isProgress}
                                variant="contained"
                                disableElevation
                                size={"small"}
                                sx={{
                                    backgroundColor: '#6F9EE8',
                                    color: '#ffffff',
                                    ':hover': {
                                        backgroundColor: '#4380E1',
                                        color: 'white',
                                    },
                                }}
                                onClick={async () => {
                                    setIsProgress(true)
                                    let res = await ApiShipmentImportFile.downloadImportFileResult({id: params.row.id})
                                    if (res.status !== 200) {
                                        setIsLoading(false)
                                        Swal.fire({
                                            title: 'ดาวน์โหลดไม่สำเร็จ',
                                            text: 'กรุณาทำรายการใหม่อีกครั้ง',
                                            icon: 'error',
                                            showConfirmButton: false,
                                        })
                                    } else {
                                        FileDownload(res.data, 'Import_result_' + params.row.fileName);
                                    }
                                    setIsProgress(false)
                                }}
                            >
                                Download
                            </Button>
                        </>
                    )
                } else {
                    return (
                        <></>
                    )
                }
                /*if (params.row.buType === 'Makro' && params.row.status != 'Waiting'){
                    return (
                        <>
                            <Button
                                disabled={isProgress}
                                variant="contained"
                                disableElevation
                                size={"small"}
                                sx={{
                                    backgroundColor: '#6F9EE8',
                                    color: '#ffffff',
                                    ':hover': {
                                        backgroundColor: '#4380E1',
                                        color: 'white',
                                    },
                                }}
                                onClick={ async ()=>{
                                    setIsProgress(true)
                                    let res = await ApiShipmentImportFile.downloadImportFileResult({id:params.row.id})
                                    if (res.status !== 200){
                                        setIsLoading(false)
                                        Swal.fire({
                                            title: 'ดาวน์โหลดไม่สำเร็จ',
                                            text: 'กรุณาทำรายการใหม่อีกครั้ง',
                                            icon: 'error',
                                            showConfirmButton: false,
                                        })
                                    } else {
                                        FileDownload(res.data, 'Import_result_'+params.row.fileName);
                                    }
                                    setIsProgress(false)
                                }}
                            >
                                Download
                            </Button>
                        </>
                    )
                } else if (params.row.failBuTracking === 1) {
                    return  (
                        <IconButton
                            sx={{p:0, m:0}}
                            onClick={ async () => {
                                let spiltData = (params.row.errorText).split(",");
                                let alertData = [];
                                let showData = spiltData.map((mss) => {
                                    if (mss) {
                                        alertData.push('- ' + mss);
                                    }
                                });
                                setAlertData(alertData);
                                await handleClickOpen();
                            }}
                        >
                            <ErrorOutlineSharpIcon color="error" />
                        </IconButton>
                    )
                } else {
                    return (
                        <></>
                    )
                }*/
            }
        },
        {
            field: 'action',
            headerClassName: 'super-app-theme--header',
            headerName: 'Action',
            minWidth: 70,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'createdBy',
            headerClassName: 'super-app-theme--header',
            headerName: 'สร้างโดย',
            minWidth: 190,
            flex: 2,
            sortable: true,
            align: 'center',
            headerAlign: 'center',
        },
        /*{
            field: 'refresh',
            headerClassName: 'super-app-theme--header',
            headerName: 'รีเฟรช',
            minWidth: 85,
            flex: 1,
            sortable: false,
            type: 'singleSelect',
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                if (params.row.status !== 'Done' && params.row.status !== 'Fail' ){
                    return (
                        <IconButton
                            sx={{p:0, m:0}}
                            onClick={async ()=>{
                                setIsLoading(true)
                                setIsProgress(true)
                                // await delay(2000);
                                // let resCreate = await ApiSubscription.create();
                                // if (resCreate.status === 200){
                                //     console.log(resCreate)
                                //     alert(JSON.stringify(resCreate.data))
                                // }
                                await fetchData().catch((e)=>{
                                    console.log(e)
                                    setIsLoading(false)
                                    setIsProgress(false)
                                })
                                console.log(isProfiles)
                                setIsLoading(false)
                                setIsProgress(false)
                            }}
                        >
                            <RefreshIcon/>
                        </IconButton>
                    )
                }
                return (
                    <></>
                )}
        },*/
    ];

    const [search, setSearch] = useState({
        submitDate: dayjs(Date.now()),
        start_date: null,
        min_start_date: dayjs(Date.now()),
        max_start_date: dayjs(Date.now()),
        fileName: '',
        status: "ทั้งหมด",
    });

    const afterApi = async (res) => {
        if (res.status !== 200) {
            setIsLoading(false)
            setIsProfiles(false)
            setUserNPass(null);
            await localStorage.setItem('userNPass', JSON.stringify(null));
        } else {
            console.log('api success')
        }
    }

    useEffect(() => {
        async function inti() {
            console.log('useEffect')
            console.log(userNPass)

            await fetchData().catch((e) => {
                console.log(e)
                setIsLoading(false)
                setIsProgress(false)
            })
        }

        inti().catch(console.error)
    }, []);

    const fetchData = async () => {
        setIsLoading(true)
        setIsProgress(true)
        let res = await ApiShipmentImportFile.search({
            date: search.submitDate ? new Date(search.submitDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
            min_start_date: search.submitDate ? new Date(search.min_start_date).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
            max_start_date: search.submitDate ? new Date(search.max_start_date).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
            fileName: search.fileName,
            status: search.status,
            customerName: bus,
            page: page,
            limit: rowsPerPage,
            isAPI: isApi
        });

        if (res.status === 200) {
            console.log(res)
            setRows(res.data.listData)
            setBuSiteCpAllCM(res.data.buSiteCpAllCM)
            setBuSiteCpAllNR(res.data.buSiteCpAllNR)
        }
        setIsLoading(false)
        setIsProgress(false)
    };

    const DrawerBtn = () => {
        setDrawerOpenBtn(!drawerOpenBtn)
        if (drawerOpenBtn === false) {
            setDrawer('block')
        }
        if (drawerOpenBtn === true) {
            setDrawer('none')
        }
    };

    const handleChangePage = async (event, newPage) => {
        setPage(newPage)
    };

    const handleChangeRowsPerPage = async (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };
    const testProgress = async () => {
        setIsProgress(true)
        await delay(2000);
        setIsProgress(false)
    };
    const testLoading = async () => {
        setIsLoading(true)
        setIsProgress(true)
        await delay(2000);
        setIsLoading(false)
        setIsProgress(false)
    };

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertData, setAlertData] = useState([]);
    const handleClickOpen = async () => {
        setAlertOpen(true);
    };
    const handleClose = () => {
        setAlertOpen(false);
        setAlertData([]);
    };

    return (
        <>
            {
                !isProfiles
                    ? <Loading/>
                    :
                    <Box sx={{display: {xs: open ? 'none' : 'block', sm: 'block'}, overflow: 'auto', pb: '30px'}}>
                        <Grid container>
                            <Grid xs={11} item>
                                <Typography variant="h5" sx={{pl: '0px', pb: '15px', fontSize: '22px'}}>
                                    Import File Page
                                </Typography>
                            </Grid>
                            <Grid container item xs={1} sx={{display: {xs: 'block', sm: 'none'},}} direction="row"
                                  alignItems="end">
                                <IconButton aria-label="delete" onClick={DrawerBtn}>
                                    {drawerOpenBtn ? <ExpandMore/> : <ExpandLess/>}
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider sx={{mb: '10px'}}/>
                        <Grid container spacing={2}
                              sx={{pt: 1, pl: {xs: 0, md: 1}, display: {xs: drawer, sm: 'block'},}}>
                            <Grid container item>
                                <Grid container item spacing={2} sx={{pb: '20px'}} direction="row" alignItems="center"
                                      justifyContent="start">
                                    <Grid container xs={12} sm={6} md={4} lg={3} item direction="column">
                                        <Typography variant="subtitle1" sx={{pr: '10px'}}>
                                            BU:
                                        </Typography>
                                        <Select
                                            fullWidth
                                            sx={{mt: '8px'}}
                                            value={bus}
                                            size="small"
                                            onChange={(e) => {
                                                setBus(e.target.value)
                                                setMakro('')
                                                setCpAllSite('')
                                                setIsApi(false)
                                            }}
                                        >
                                            {profiles.bu.map((m) => {
                                                return <MenuItem value={m}>{m}</MenuItem>
                                            })}
                                        </Select>
                                    </Grid>
                                    {
                                        bus === 'Makro' && !isNew
                                            ?
                                            <Grid container xs={12} sm={6} md={4} lg={3} item direction="column">
                                                <Typography variant="subtitle1" sx={{pr: '10px'}}>
                                                    สาขา:
                                                </Typography>
                                                <Select
                                                    fullWidth
                                                    sx={{mt: '8px'}}
                                                    value={makro}
                                                    size="small"
                                                    onChange={(e) => {
                                                        setMakro(e.target.value)
                                                    }}
                                                >
                                                    <MenuItem value={'บางนา'}>{'บางนา'}</MenuItem>
                                                    <MenuItem value={'วังน้อย'}>{'วังน้อย'}</MenuItem>
                                                    <MenuItem value={'วังน้อย2'}>{'วังน้อย2'}</MenuItem>
                                                </Select>
                                            </Grid>
                                            :
                                            <></>
                                    }

                                    {
                                        bus === 'Makro' ?
                                            <Grid container xs={12} sm={6} md={4} lg={3} item direction="column" alignItem="end">
                                                <FormGroup>
                                                    <FormControlLabel
                                                        checked={isNew}
                                                        style={{ marginRight: '25px', marginTop: '35px' }}
                                                        control={<Checkbox />}
                                                        label="New Template"
                                                        onChange={(event) => {
                                                            setIsNew(event.target.checked);
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Grid> : null
                                    }

                                    {
                                        bus === 'CPALL เชียงใหม่' || bus === 'CPALL นครราชสีมา'
                                            ?
                                            <Grid container xs={12} sm={6} md={4} lg={3} item direction="column">
                                                <Typography variant="subtitle1" sx={{pr: '10px'}}>
                                                    สาขา:
                                                </Typography>
                                                <Select
                                                    fullWidth
                                                    sx={{mt: '8px'}}
                                                    value={cpAllSite}
                                                    size="small"
                                                    onChange={(e) => {
                                                        setCpAllSite(e.target.value)
                                                    }}
                                                >
                                                    {
                                                        bus === 'CPALL เชียงใหม่'
                                                            ?
                                                            buSiteCpAllCM.map((site) => {
                                                                return <MenuItem value={site}>{site}</MenuItem>
                                                            })
                                                            :
                                                            buSiteCpAllNR.map((site) => {
                                                                return <MenuItem value={site}>{site}</MenuItem>
                                                            })
                                                    }
                                                </Select>
                                            </Grid>
                                            :
                                            <></>
                                    }
                                </Grid>

                                {
                                    isApi === true ?
                                        <div>
                                            <Grid container spacing={2} sx={{py: '20px'}} item>
                                                <Grid xs={12} sm={3} md={3} lg={2} item>
                                                    <Typography variant="subtitle1">
                                                        Min start date:
                                                    </Typography>
                                                    <TextField
                                                        value={search.start_date}
                                                        fullWidth
                                                        type="number"
                                                        size={'small'}
                                                        inputProps={{ min: 0, max: 10, width: '50px' }}
                                                        min={0}
                                                        max={100}
                                                        onChange={(e) => {
                                                            setSearch(search => ({...search, ...{start_date: e.target.value}}))
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={6} md={4} lg={4} item>
                                                    <Typography variant="subtitle1">
                                                        Start date:
                                                    </Typography>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                        <div style={{ display: 'flex', width: '100%' }}>
                                                            <DatePicker
                                                                sx={{ pr: '10px' }}
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={search.min_start_date}
                                                                onChange={(newValue) => {
                                                                    setSearch(search => ({...search, ...{min_start_date: newValue}}))
                                                                }}
                                                                componentsProps={{
                                                                    actionBar: {
                                                                        actions: ['clear'],
                                                                    },
                                                                }}
                                                                renderInput={(params) => <TextField {...params} fullWidth/>}
                                                            />
                                                            <TimePicker
                                                                label=""
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={search.min_start_date}
                                                                onChange={(newValue) => {
                                                                    setSearch(search => ({...search, ...{min_start_date: newValue}}))
                                                                }}
                                                            />
                                                        </div>
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4} item>
                                                    <Typography variant="subtitle1">
                                                        End date:
                                                    </Typography>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                        <div style={{ display: 'flex', width: '100%' }}>
                                                            <DatePicker
                                                                sx={{ pr: '10px' }}
                                                                value={search.max_start_date}
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                onChange={(newValue) => {
                                                                    setSearch(search => ({...search, ...{max_start_date: newValue}}))
                                                                }}
                                                                componentsProps={{
                                                                    actionBar: {
                                                                        actions: ['clear'],
                                                                    },
                                                                }}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                            <TimePicker
                                                                label=""
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={search.max_start_date}
                                                                onChange={(newValue) => {
                                                                    setSearch(search => ({...search, ...{max_start_date: newValue}}))
                                                                }}
                                                            />
                                                        </div>
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid container xs={12} sm={6} md={4} lg={3} item direction="column">
                                                    <div style={{ display: 'flex', width: '100%' }}>
                                                        <div style={{ paddingRight: '10px', width: '100%' }}>
                                                            <Typography variant="subtitle1" sx={{pr: '10px'}}>
                                                                Truckr status:
                                                            </Typography>
                                                            <Autocomplete
                                                                size="small"
                                                                multiple
                                                                fullWidth
                                                                onChange={(e, array) => {
                                                                    const idSet = new Set();
                                                                    const hasDuplicates = array.some(item => {
                                                                        if (idSet.has(item.id)) {
                                                                            return true; // Duplicate found
                                                                        }
                                                                        idSet.add(item.id);
                                                                        return false;
                                                                    });

                                                                    if (!hasDuplicates) {
                                                                        setStatus(prevState => ({...prevState, truckrStatus: array}))
                                                                    }
                                                                }}
                                                                getOptionLabel={(option) => option.name}
                                                                options={truckrStatus}
                                                                value={status.truckrStatus}
                                                                clearOnEscape
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        size={'small'}
                                                                        sx={{'& legend': {display: 'none'}, '& fieldset': {top: 0},}}
                                                                        variant="outlined"
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                        }}
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            endAdornment: (
                                                                                <>
                                                                                    {/*{paramss.row.vendorLoading ? <CircularProgress size={20}/> : null}*/}
                                                                                    {params.InputProps.endAdornment}
                                                                                </>
                                                                            ),
                                                                        }}
                                                                    />
                                                                )}

                                                            />
                                                        </div>
                                                        <div>
                                                            <Typography variant="subtitle1" sx={{pr: '10px'}}>
                                                                Debrief:
                                                            </Typography>
                                                            <Select
                                                                fullWidth
                                                                value={status.debrief}
                                                                size="small"
                                                                onChange={(e) => {
                                                                    setStatus(prevState => ({...prevState, debrief: e.target.value}))
                                                                }}
                                                            >
                                                                <MenuItem value={true}>true</MenuItem>
                                                                <MenuItem value={false}>false</MenuItem>
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div> :
                                        <div>
                                            <Typography>เลือกไฟล์ที่ต้องการอัพโหลด</Typography>
                                            <Grid container direction="column" alignItems="center"
                                                  justifyContent="flex-start" sx={{pt: '8px',}}>
                                                <Grid container sx={{pl: '20px'}} direction="row" alignItems="center"
                                                      justifyContent="start" item>
                                                    <input
                                                        type={"file"}
                                                        accept={".xlsx"}
                                                        onChange={(e) => {
                                                            if (e.target.files[0]) {
                                                                /*if (e.target.files[0].size > 10000000){
                                                                    setFileSize(0)
                                                                    alert('ขนาดของไฟล์ต้องไม่เกิน 5MB เท่านั้น')
                                                                } else {
                                                                    setFile(e.target.files[0])
                                                                    setFileSize(e.target.files[0].size / 1000)
                                                                }*/
                                                                setFile(e.target.files[0])
                                                                setFileSize(e.target.files[0].size / 1000)
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid container sx={{pl: '20px'}} direction="row" alignItems="center"
                                                      justifyContent="start" item>
                                                    <Typography variant="subtitle1" sx={{pr: '10px'}}>
                                                        File size : {fileSize} KB
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                }

                                <Grid item container direction="row" justifyContent="flex-end" alignItems="center">

                                    {
                                        trucker.includes(bus) ?
                                            <div>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        checked={isApi}
                                                        style={{ marginRight: '25px' }}
                                                        control={<Checkbox />}
                                                        label="ระบบ Truckr"
                                                        onChange={(event) => {
                                                            setIsApi(event.target.checked);
                                                        }}
                                                    />
                                                </FormGroup>
                                            </div> : null
                                    }

                                    {
                                        checkingTempButton(bus) === true ?
                                            <Button
                                                disabled={isProgress}
                                                variant="contained"
                                                disableElevation
                                                onClick={async ()=> {
                                                    setIsLoading(true)

                                                    let res = await ApiShipmentImportFile.downloadTemp()
                                                    console.log("===== Debug Download Temp: ", res);
                                                    if (res.data) {
                                                        FileDownload(res.data, 'ImportShipmentTemplate.xlsx');
                                                        Swal.fire({
                                                            title: 'Download Template Success',
                                                            icon: 'success',
                                                            showConfirmButton: false,
                                                        }).then(() => {})
                                                    } else {
                                                        Swal.fire({
                                                            title: 'Download Template Fail',
                                                            icon: 'error',
                                                            showConfirmButton: false,
                                                        }).then(() => {})
                                                    }

                                                    setIsLoading(false)
                                                }}
                                                sx={{
                                                    mr: '5px',
                                                    backgroundColor: '#4895ef',
                                                    color: '#ffffff',
                                                    width: '180px',
                                                    ':hover': {
                                                        backgroundColor: '#1a61b5',
                                                        color: 'white',
                                                    },
                                                }}
                                            >
                                                Load Template
                                            </Button> : <></>
                                    }

                                    {
                                        checkingUpdateButton(bus) === true ?
                                            <Button
                                                disabled={isProgress}
                                                variant="contained"
                                                disableElevation
                                                onClick={async () => {
                                                    if (file.length >= 0 && !(isApi && trucker.includes(bus))) {
                                                        alert('เลือกไฟล์ที่ต้องการอัพโหลด')
                                                    } else if (bus === null) {
                                                        alert('เลือก Bus')
                                                    } else if (bus === 'Makro' && makro === '' && isNew === false) {
                                                        alert('กรุณาระบุเลือกสาขา')
                                                    } else {
                                                        setIsLoading(true)

                                                        const dataImport = {
                                                            file: file,
                                                            bu: bus,
                                                            makro: makro,
                                                            cpall: cpAllSite,
                                                            userId: JSON.parse(localStorage.getItem("profile")).userId,
                                                            action: "Update",
                                                            isAPI: isApi,
                                                            isNew: isNew,
                                                            truckrStatus: getTruckrStatus(status.truckrStatus),
                                                            debrief: status.debrief,
                                                            start_date: search.start_date,
                                                            min_start_date: search.min_start_date ? new Date(search.min_start_date).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                            max_start_date: search.max_start_date ? new Date(search.max_start_date).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null
                                                        };

                                                        let resImportFileXlsx = await ApiShipmentImportFile.importFile(dataImport)
                                                        console.log(resImportFileXlsx)
                                                        if (resImportFileXlsx.status !== 200) {
                                                            Swal.fire({
                                                                title: 'อัพโหลดไม่สำเร็จ',
                                                                text: 'กรุณาทำรายการใหม่อีกครั้ง',
                                                                icon: 'error',
                                                                showConfirmButton: false,
                                                            })
                                                        } else {
                                                            console.log(resImportFileXlsx.data)
                                                            Swal.fire({
                                                                title: 'อัพโหลดสำเร็จ',
                                                                icon: 'success',
                                                                showConfirmButton: false,
                                                            }).then(async () => {
                                                                await fetchData().catch((e) => {
                                                                    console.log(e)
                                                                    setIsLoading(false)
                                                                    setIsProgress(false)
                                                                })
                                                            })
                                                        }

                                                        // await afterApi(resImportFileXlsx);
                                                        setIsLoading(false)
                                                    }
                                                }}
                                                sx={{
                                                    mr: '5px',
                                                    backgroundColor: '#e8de6f',
                                                    color: '#ffffff',
                                                    width: '100px',
                                                    ':hover': {
                                                        backgroundColor: '#dce143',
                                                        color: 'white',
                                                    },
                                                }}
                                            >
                                                Update
                                            </Button> : <></>
                                    }

                                    <Button
                                        disabled={isProgress}
                                        variant="contained"
                                        disableElevation
                                        onClick={async () => {
                                            if (file.length >= 0 && !(isApi && trucker.includes(bus))) {
                                                alert('เลือกไฟล์ที่ต้องการอัพโหลด')
                                            } else if (bus === null) {
                                                alert('เลือก Bus')
                                            } else if (bus === 'Makro' && makro === '' && isNew === false) {
                                                alert('กรุณาระบุเลือกสาขา')
                                            } else if ((bus === 'CPALL เชียงใหม่' || bus === 'CPALL นครราชสีมา') && cpAllSite === '') {
                                                alert('กรุณาระบุเลือกสาขา')
                                            }
                                            else {
                                                setIsLoading(true)

                                                // Makro old template FH/FZ
                                                if (
                                                    bus === "Makro Fresh - Wangnoi" ||
                                                    bus === "Makro Fresh - Mahachai" ||
                                                    bus === "Makro Frozen - Wangnoi" ||
                                                    bus === "Makro Frozen - Mahachai" ||
                                                    bus === "Makro Transfer"
                                                ) {
                                                    const request = new FormData();
                                                    request.append('file', file);
                                                    request.append('bu', bus);
                                                    request.append('userId', JSON.parse(localStorage.getItem("profile")).userId);
                                                    request.append('action', "Import");

                                                    let response = await ApiShipmentImportFile.importFileToSynx(request)
                                                    setIsLoading(false)

                                                    if (response.status === 200) {
                                                        Swal.fire({
                                                            title: 'อัพโหลดสำเร็จ',
                                                            icon: 'success',
                                                            showConfirmButton: false,
                                                        }).then(async () => {
                                                            setIsLoading(true)
                                                            console.log("Debug response import file => ", response.data.entry)

                                                            if (response.data?.entry) {
                                                                let res = await ApiShipmentImportFile.autoApprove(response.data?.entry)
                                                                console.log("Debug response auto approve => ", res)
                                                            }

                                                            await fetchData().catch((e) => {
                                                                console.log(e)
                                                                setIsLoading(false)
                                                                setIsProgress(false)
                                                            })
                                                        })
                                                    } else {
                                                        Swal.fire({
                                                            title: 'อัพโหลดไม่สำเร็จ',
                                                            text: 'กรุณาทำรายการใหม่อีกครั้ง',
                                                            icon: 'error',
                                                            showConfirmButton: false,
                                                        })
                                                    }
                                                } else {
                                                    const dataImport = {
                                                        file: file,
                                                        bu: bus,
                                                        makro: makro,
                                                        cpall: cpAllSite,
                                                        userId: JSON.parse(localStorage.getItem("profile")).userId,
                                                        action: "Import",
                                                        isAPI: isApi,
                                                        isNew: isNew,
                                                        truckrStatus: getTruckrStatus(status.truckrStatus),
                                                        debrief: status.debrief,
                                                        start_date: search.start_date,
                                                        min_start_date: search.min_start_date ? new Date(search.min_start_date).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                        max_start_date: search.max_start_date ? new Date(search.max_start_date).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null
                                                    };

                                                    let resImportFileXlsx = await ApiShipmentImportFile.importFile(dataImport)
                                                    if (resImportFileXlsx.status !== 200) {
                                                        console.log("===== Debug data error: ", resImportFileXlsx)

                                                        const error = resImportFileXlsx.response.data.error
                                                        let content = ""

                                                        if (error) {
                                                            content += "<div style='width: 100%; text-align: left; max-height: 300px; overflow-x: hidden'>"

                                                            error.forEach(data => {
                                                                content += "<div style='margin-bottom: 5px; font-weight: bold'>ข้อมูลแถวที่ " + data.index + "</div>"

                                                                data.error.forEach(message => {
                                                                    content += "<div style='font-size: 14px; margin-left: 5px'>- " + message + "</div>"
                                                                })
                                                            })

                                                            content += "</div>"
                                                        } else {
                                                            content += "<div>กรุณาทำรายการใหม่อีกครั้ง</div>"
                                                        }

                                                        Swal.fire({
                                                            title: 'อัพโหลดไม่สำเร็จ',
                                                            html: content,
                                                            icon: 'error',
                                                            showConfirmButton: false,
                                                        })
                                                    } else {
                                                        Swal.fire({
                                                            title: 'อัพโหลดสำเร็จ',
                                                            icon: 'success',
                                                            showConfirmButton: false,
                                                        }).then(async () => {

                                                            console.log("===== Debug data resImportFileXlsx: ", resImportFileXlsx)
                                                            if (resImportFileXlsx.data) {
                                                                let res = await ApiShipmentImportFile.autoApprove(resImportFileXlsx.data)
                                                                console.log("Debug response auto approve => ", res)
                                                            }

                                                            await fetchData().catch((e) => {
                                                                console.log(e)
                                                                setIsLoading(false)
                                                                setIsProgress(false)
                                                            })
                                                        })
                                                    }
                                                }
                                            }

                                            // await afterApi(resImportFileXlsx);
                                            setIsLoading(false)
                                        }}
                                        sx={{
                                            // fontWeight: 'bold',
                                            backgroundColor: '#6F9EE8',
                                            color: '#ffffff',
                                            width: '100px',
                                            ':hover': {
                                                backgroundColor: '#4380E1',
                                                color: 'white',
                                            },
                                        }}
                                    >
                                        Import
                                    </Button>
                                </Grid>
                            </Grid>
                            <Divider sx={{mt: '20px'}}/>
                            <Grid container spacing={2} item>
                                {/*search*/}
                                <Grid xs={12} sm={6} md={4} lg={4} item>
                                    <Typography variant="subtitle1">
                                        วันที่อัพโหลด:
                                    </Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                        <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                            <DatePicker
                                                value={search.submitDate}
                                                // label={'DD/MM/YY'}
                                                // views={['day', 'month', 'year']}
                                                onChange={(newValue) => {
                                                    setSearch(search => ({...search, ...{submitDate: newValue}}))
                                                    // console.log(newValue)
                                                }}
                                                componentsProps={{
                                                    actionBar: {
                                                        actions: ['clear'],
                                                    },
                                                }}
                                                renderInput={(params) => <TextField {...params} fullWidth
                                                                                    sx={{py: '3px'}}/>}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>

                                <Grid xs={12} sm={6} md={4} lg={4} item>
                                    <Typography variant="subtitle1">
                                        ชื่อไฟล์:
                                    </Typography>
                                    <TextField
                                        value={search.fileName}
                                        fullWidth
                                        sx={{pt: '8px'}}
                                        onChange={(e) => {
                                            setSearch(search => ({...search, ...{fileName: e.target.value}}))
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} md={4} lg={4} item>
                                    <Typography variant="subtitle1">
                                        สถาน:
                                    </Typography>


                                    <Select
                                        fullWidth
                                        sx={{height: '56px', mt: '8px'}}
                                        value={search.status}
                                        onChange={(e) => {
                                            setSearch(search => ({
                                                ...search,
                                                ...{status: e.target.value}
                                            }))
                                        }}
                                    >
                                        <MenuItem value={'ทั้งหมด'}>ทั้งหมด</MenuItem>
                                        <MenuItem value={'Waiting'}>Waiting</MenuItem>
                                        <MenuItem value={'Processing'}>Processing</MenuItem>
                                        <MenuItem value={'Done'}>Done</MenuItem>
                                        <MenuItem value={'Fail'}>Fail</MenuItem>

                                    </Select>

                                    {/*<TextField*/}
                                    {/*    fullWidth*/}
                                    {/*    sx={{pt: '8px'}}*/}
                                    {/*    type="text"*/}
                                    {/*    required*/}
                                    {/*/>*/}
                                </Grid>
                                <Grid xs={12} sm={12} md={12} lg={12} item sx={{pt: 1}} container
                                      direction="row"
                                      justifyContent="flex-end"
                                      alignItems="end"
                                >
                                    <Button
                                        disabled={isProgress}
                                        variant="contained"
                                        disableElevation
                                        onClick={async () => {
                                            console.log({
                                                date: search.submitDate ? new Date(search.submitDate).toLocaleString("en-GB", {timeZone: 'Asia/Bangkok'}) : null,
                                                fileName: search.fileName,
                                                status: search.status,
                                                page: page,
                                                limit: rowsPerPage,
                                            })
                                            await fetchData().catch((e) => {
                                                console.log(e)
                                                setIsLoading(false)
                                                setIsProgress(false)
                                            })
                                        }}
                                        sx={{
                                            // fontWeight: 'bold',
                                            backgroundColor: '#6F9EE8',
                                            color: '#ffffff',
                                            width: '100px',
                                            ':hover': {
                                                backgroundColor: '#4380E1',
                                                color: 'white',
                                            },
                                        }}
                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>
                            {/*<Divider />*/}
                        </Grid>
                        <Grid container>
                            <DataGrid
                                sx={{
                                    // minWidth:'1000px',
                                    mt: '15px',
                                    flex: 21,
                                    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                                        outline: 'none',
                                    },
                                    '&.MuiDataGrid-root .MuiDataGrid-cell': {
                                        whiteSpace: "normal !important",
                                        wordWrap: "break-word !important",
                                        textAlign: 'center',
                                    },
                                    // minWidth: '60%',
                                }}
                                autoHeight
                                loading={isProgress}
                                // rows={allListData}
                                rows={rows}
                                columns={columns}
                                slots={{
                                    toolbar: GridToolbar,
                                    loadingOverlay: LinearProgress,
                                }}
                                componentsProps={{
                                    toolbar: {
                                        setRows,
                                        csvOptions: {disableToolbarButton: true},
                                        printOptions: {disableToolbarButton: true},
                                    },
                                }}
                                initialState={{
                                    pagination: {paginationModel: {pageSize: 10, page: 0}},
                                }}
                                rowSelection={false}
                                disableSelectionOnClick
                                disableDensitySelector
                                disableColumnFilter
                                disableColumnMenu
                                headerAlign="center"
                                pageSize={pageSize}
                                // rowsPerPageOptions={[10, 50, 100]}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                pageSizeOptions={[10, 50, 100]}
                            />

                        </Grid>
                        {/*<Button*/}
                        {/*    variant="contained"*/}
                        {/*    disableElevation*/}
                        {/*    onClick={async ()=>{*/}
                        {/*        */}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    test*/}
                        {/*</Button>*/}
                    </Box>
            }
            <div>
                {/*<Button variant="outlined" onClick={handleClickOpen}>
                    Open alert dialog
                </Button>*/}
                <Dialog
                    open={alertOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"แจ้งเตือน"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {Array.isArray(alertData) ? alertData.map((data) => {
                                return (
                                    <>
                                        <Grid
                                            item
                                            container
                                            direction="column"
                                        >
                                            <Typography>{data}</Typography>
                                        </Grid>
                                    </>
                                )
                            }) : null}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}> ปิด </Button>
                        {/*<Button onClick={handleClose} autoFocus> รับทราบ </Button>*/}
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )

    function checkingUpdateButton(str) {
        return str?.toLowerCase()?.includes("makro")  || str?.toLowerCase()?.includes("lotus")
    }

    function checkingTempButton(str) {
        return str?.toLowerCase()?.includes("makro")
    }

    function getTruckrStatus(truckrStatus) {
        let data = []
        truckrStatus.map(m => {
            data.push(m.id)
        })

        return data
    }
}