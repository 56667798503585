import {confirmAlert} from "react-confirm-alert";
import {Button, createTheme, Stack, ThemeProvider} from '@mui/material';
import Card from "@mui/material/Card";
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import React, {useEffect, useState} from "react";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CustomIOSSwitchComponent from "../components/CustomIOSSwitchComponent";
import Paper from "@mui/material/Paper";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Avatar from "@mui/material/Avatar";
import { deepOrange, deepPurple } from '@mui/material/colors';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function ManageColumnsDialog(
    title,
    columns,
    columnsDataGrid,
    columnVisibility,
    onColumnVisibilityChange,
    onIndexChange
) {
    const options = {
        customUI: view,
        closeOnEscape: false,
        closeOnClickOutside: false,
        overlayClassName: "overlay-custom-class-name"
    };

    confirmAlert(options);

    function view({onClose}) {

        return (
            <ThemeProvider theme={theme}>
                <Card>
                    <div
                        className='center'
                        style={{
                            position: 'relative',
                            minWidth: '450px',
                            maxWidth: '90vw',
                            padding: '16px 0 0 0'
                        }}
                    >
                        <div
                            style={{
                                cursor: 'pointer',
                                width: '24px',
                                height: '24px',
                                position: 'absolute',
                                right: '10px'
                            }}
                            onClick={onClose}
                        >
                            <CloseIcon/>
                        </div>

                        <div>
                            <div style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                textAlign: 'left',
                                marginBottom: '16px',
                                paddingLeft: '16px',
                                paddingRight: '16px'
                            }}
                                 dangerouslySetInnerHTML={{
                                     __html: title || '-'
                                 }}/>
                            <Divider/>
                        </div>

                        <div style={{ minHeight: '200px' }}>
                            <ManageColumnsDialogView
                                columnVisibility={columnVisibility}
                                onClose={onClose}
                                columns={columns}
                                columnsDataGrid={columnsDataGrid}
                                onIndexChange={onIndexChange}
                                onColumnVisibilityChange={onColumnVisibilityChange}
                            />
                        </div>
                    </div>
                </Card>
            </ThemeProvider>
        )
    }
}

function ManageColumnsDialogView(props) {
    const [isLoaded, setIsLoaded] = useState(false)
    const [columns, setColumns] = useState([])
    const [columnVisibility, setColumnVisibility] = useState(props.columnVisibility || {});

    useEffect(() => {
        const propsColumns = props.columnsDataGrid || []
        const result = propsColumns.reduce((acc, obj, index) => {
            const field = obj.field
            const headerName = obj.headerName

            acc.push({ field, headerName, index });
            return acc
        }, []);

        setColumns(result)

        if (!isLoaded) {
            setIsLoaded(true)
        }
    }, [])

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    return !isLoaded ? null : (
        <div style={{ marginBottom: '16px' }}>
            <div style={{ maxHeight: "500px", overflow: 'hidden', overflowY: 'scroll'}}>
                <DragDropContext onDragEnd={(result) => {
                    if (!result.destination) {
                        return;
                    }

                    const items = reorder(
                        columns,
                        result.source.index,
                        result.destination.index
                    );

                    const reduceResult = items.reduce((acc, obj, index) => {
                        acc.push({ ...obj, index });
                        return acc
                    }, []);

                    console.log("===== Debug reorder result: ", reduceResult)
                    setColumns(reduceResult)
                }}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{ padding: '5px 10px', backgroundColor: '#f3f3f3', paddingBottom: '16px' }}
                            >
                                {
                                    columns?.map((item, index) => (
                                        <Draggable
                                            key={item.field + "_" + index}
                                            draggableId={item.field}
                                            index={index}
                                            // isDragDisabled={true}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <ColumnsComponentView
                                                        index={index}
                                                        data={item}
                                                        switchChecked={columnVisibility[item.field] ?? true}
                                                        columnVisibilityChange={(value) => {
                                                            let newData = {...columnVisibility, [item.field]: value}
                                                            setColumnVisibility(newData)
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))
                                }

                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>

            <Divider/>

            <Stack direction="row" spacing={2} style={{
                justifyContent: 'space-between',
                paddingTop: '16px',
                paddingRight: '16px',
                paddingLeft: '16px'
            }}>
                <Button
                    id="dialog-btn-close"
                    variant={"outlined"}
                    size={'small'}
                    color={'error'}
                    style={{width: '120px', fontWeight: 'lighter'}}
                    onClick={() => {
                        const propsColumns = props.columns || []
                        const result = propsColumns.reduce((acc, obj, index) => {
                            const field = obj.field
                            const headerName = obj.headerName

                            acc.push({ field, headerName, index });
                            return acc
                        }, []);

                        setColumns(result)
                        setColumnVisibility({})
                    }}
                >คืนค่าเริ่มต้น</Button>

                <Button
                    id="dialog-btn-close"
                    variant={"contained"}
                    color="info"
                    size={'small'}
                    style={{
                        width: '120px',
                        fontWeight: 'lighter',
                        color: 'white',
                    }}
                    onClick={() => {
                        if (props.onColumnVisibilityChange && props.onIndexChange) {
                            props.onIndexChange(columns)
                            props.onColumnVisibilityChange(columnVisibility)
                        }

                        props.onClose()
                    }}
                >บันทึก</Button>
            </Stack>
        </div>
    )
}

function ColumnsComponentView(props) {
    const [checked, setChecked] = useState(props.switchChecked ?? false)

    useEffect(() => {

    }, [])

    return(
        <Paper style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 1px 5px 0px" }}>
            <div style={{ width: '100%', padding: '3px 10px', marginTop: '14px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                        style={{ fontSize: '13px' }}
                        sx={{ bgcolor: (props.switchChecked ?? false) === true ? "#7678ed" : "#d3d3d3", width: '24px', height: '24px', marginRight: '10px' }}
                    >
                        {props.index + 1}
                    </Avatar>
                    <div>{props.data?.headerName ?? ""}</div>
                </div>

                <CustomIOSSwitchComponent
                    checked={props.switchChecked ?? false}
                    onChange={(event) => {
                        const value = event.target.checked

                        if (props.columnVisibilityChange) {
                            props.columnVisibilityChange(value)
                        }
                    }}
                />
            </div>
        </Paper>
    )
}

const styleOverride = {
    styleOverrides: {
        root: {
            fontFamily: 'Kanit',
            fontSize: 16,
        },
    }
}

const theme = createTheme({
    components: {
        MuiOutlinedInput: styleOverride,
        MuiInputLabel: styleOverride,
        MuiMenuItem: styleOverride,
        MuiButton: styleOverride,
        MuiAvatar: styleOverride,
    },
    palette: {
        primary: {
            main: '#302E2E',
        },
        secondary: {
            main: '#747474',
        },
        info: {
            main: '#397EF5',
        },
        error: {
            main: '#B64648',
        },
        warning: {
            main: '#B64648',
        },
    },
});